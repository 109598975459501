import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPlatformList, addPlatform, editPlatform, deletePlatform as _deletePlatform } from '@/api/ceshi_xiugai/popularize';
import Head from '@/components/head/index';
export default {
  name: 'Extension',
  components: {
    Head: Head
  },
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      title: '推广平台管理',
      tableData: [],
      props: {
        multiple: true
      },
      loading: true,
      fullscreenLoading: false,
      platformName: '',
      dialogPvVisible: false,
      PersonnelList: '',
      dialogPlatform: false,
      rules: {
        name: [{
          required: true,
          message: '请输入必填项',
          trigger: 'blur'
        }]
      },
      vals: '',
      temp: {
        id: '',
        name: '' //名称
      }
    };
  },
  mounted: function mounted() {
    this.getPlatform();
  },
  methods: {
    getPlatform: function getPlatform() {
      var _this = this;
      this.loading = true;
      getPlatformList({
        name: this.platformName
      }).then(function (respomse) {
        _this.tableData = respomse.data;
        _this.loading = false;
      });
    },
    resetTemps: function resetTemps() {
      //清空公告弹窗
      this.temp.name = ''; //名称
    },
    handleEdit: function handleEdit(val, key) {
      //  新增/新增子栏目/编辑弹窗
      if (val == 'edit') {
        this.vals = '编辑';
        this.dialogPlatform = true;
        this.temp.id = key.id;
        this.temp.name = key.name;
      } else if (val == 'newly2') {
        this.vals = '添加子级';
        this.dialogPlatform = true;
        this.temp.id = key.id;
      } else if (val == 'newly1') {
        this.vals = '新增';
        this.dialogPlatform = true;
      }
    },
    createData: function createData() {
      var _this2 = this;
      var data = {
        id: String(this.temp.id),
        name: this.temp.name
      };
      if (this.vals == '新增') {
        addPlatform(data).then(function (respomse) {
          _this2.getPlatform();
          _this2.resetTemps();
          _this2.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success',
            duration: 2000
          });
          _this2.dialogPlatform = false;
        });
      } else if (this.vals == '添加子级') {
        addPlatform(data).then(function (respomse) {
          _this2.getPlatform();
          _this2.resetTemps();
          _this2.$notify({
            title: '成功',
            message: '添加子级成功',
            type: 'success',
            duration: 2000
          });
          _this2.dialogPlatform = false;
        });
      } else if (this.vals == '编辑') {
        this.fullscreenLoading = true;
        editPlatform(data).then(function (respomse) {
          _this2.getPlatform();
          _this2.resetTemps();
          _this2.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success',
            duration: 2000
          });
          _this2.fullscreenLoading = false;
          _this2.dialogPlatform = false;
          _this2.temp.id = '';
        }).catch(function () {
          _this2.fullscreenLoading = false;
        });
      }
    },
    cancel: function cancel() {
      this.dialogPlatform = false;
      this.temp.id = '';
      this.temp.name = '';
    },
    deletePlatform: function deletePlatform(row) {
      var _this3 = this;
      this.$confirm('推广平台删除后将不可恢复，是否继续？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _deletePlatform({
          id: String(row.id)
        }).then(function (respomse) {
          _this3.getPlatform();
          _this3.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    ClassSearchInput: function ClassSearchInput(val) {
      this.getPlatform();
    }
  }
};