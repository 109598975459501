import { render, staticRenderFns } from "./extensionPlatform.vue?vue&type=template&id=1584f9e9"
import script from "./extensionPlatform.vue?vue&type=script&lang=js"
export * from "./extensionPlatform.vue?vue&type=script&lang=js"
import style0 from "./extensionPlatform.vue?vue&type=style&index=0&id=1584f9e9&lang=scss"
import style1 from "./extensionPlatform.vue?vue&type=style&index=1&id=1584f9e9&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/crm_web_5uHk/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1584f9e9')) {
      api.createRecord('1584f9e9', component.options)
    } else {
      api.reload('1584f9e9', component.options)
    }
    module.hot.accept("./extensionPlatform.vue?vue&type=template&id=1584f9e9", function () {
      api.rerender('1584f9e9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/BasicOperation/extensionManagement/extensionPlatform.vue"
export default component.exports