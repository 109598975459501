var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container framework" }, [
    _vm.course != 1
      ? _c("div", { staticStyle: { width: "100%" } }, [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-header",
                { staticStyle: { height: "88px" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                    [_c("Head", { attrs: { name: _vm.title } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 13 } },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["新增"],
                                  expression: "['新增']",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit("newly1", "")
                                },
                              },
                            },
                            [_vm._v("\n                            新增")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticStyle: { float: "right" }, attrs: { span: 4 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: {
                                clearable: "",
                                placeholder: "平台名称搜索",
                                size: "small",
                              },
                              model: {
                                value: _vm.platformName,
                                callback: function ($$v) {
                                  _vm.platformName = $$v
                                },
                                expression: "platformName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.ClassSearchInput },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tb",
                  staticStyle: { width: "100%", "margin-bottom": "20px" },
                  attrs: {
                    data: _vm.tableData,
                    "row-key": "id",
                    border: "",
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "推广平台名称",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_realname",
                      label: "创建人",
                      width: "280",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      width: "260",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "update_time",
                      label: "编辑时间",
                      width: "260",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "260", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["添加子级"],
                                      expression: "['添加子级']",
                                    },
                                  ],
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit("newly2", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("添加子级")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["编辑"],
                                      expression: "['编辑']",
                                    },
                                  ],
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit("edit", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              !scope.row.children
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["删除"],
                                          expression: "['删除']",
                                        },
                                      ],
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deletePlatform(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      165082386
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticStyle: { margin: "0 auto" },
                  attrs: {
                    title: _vm.vals,
                    visible: _vm.dialogPlatform,
                    width: "600px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogPlatform = $event
                    },
                    close: _vm.resetTemps,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: {
                        "min-width": "200px",
                        "max-width": "400px",
                        "margin-left": "50px",
                      },
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.temp,
                        "label-position": "right",
                        "label-width": "130px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广平台名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入推广平台名称",
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.temp.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "name", $$v)
                              },
                              expression: "temp.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("el-button", { on: { click: _vm.cancel } }, [
                        _vm._v("取消"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.fullscreenLoading,
                              expression: "fullscreenLoading",
                              modifiers: { fullscreen: true, lock: true },
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }